<template>
  <div class="download-list-box">
    <div class="download-list-btn" @click="hide = true">
        <i class="el-icon-folder"></i>
    </div>
    <div class="download-list" v-show="hide">
        <div class="down-operation-bar">
            <span>Downloading...</span>
            <div class="ope-hide-btn" @click="hide = false"><i class="el-icon-minus"></i></div>
        </div>
        <div class="list">
            <ul>
                <!-- <li class="item" v-for="item in fileNames" :key="item">
                    <DownloadItem :fileName="list[item].name"></DownloadItem>
                </li> -->
                <li class="item" v-for="item in downloads" :key="item.id">
                    <div style="display: flex; align-items: center; padding: 10px; font-size: 16px; color: #666;">
                        <span class="file-name">{{ item.name }}</span>
                    </div>
                    <div :style="{width: item.progress + '%', backgroundColor: 'rgba(199, 0, 11, 0.18)', height: '100%'}" style="position: absolute; top: 0px; z-index: -1; border-radius: 5px;"></div>
                </li>
            </ul>
        </div>
    </div>
  </div>
</template>

<script>
    import { mapState } from 'vuex';
    export default {
        computed: {
            ...mapState('download1', ['downloads', 'downloadList'])
        },
        data() {
            return {
                hide: false
            }
        },
        watch: {
            "$store.state.download1.downloads.length": {
                handler(n, o) {
                    this.hide = true
                }
            }
        }
    }
</script>

<style lang="less" scoped>
.download-list-box {
    .download-list-btn {
        position: fixed;
        background-color: #FFFFFF;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        color: #c7000b;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        box-shadow: 0 0 6px #0000001f;
        cursor: pointer;
        z-index: 5;
        right: 20px;
        bottom: 40px;
        &:hover {
            background-color: #F2F6FC;
        }
    }
    .download-list {
        position: fixed;
        background-color: #FFFFFF;
        width: 430px;
        height: 300px;
        border-radius: 6px;
        z-index: 5;
        right: 160px;
        bottom: 40px;
        // right: 20px;
        // bottom: 160px;
        font-size: 20px;
        box-shadow: 0 0 9px 0px #bfbbbb69;
        overflow: hidden;
        .down-operation-bar {
            font-size: 14px;
            text-align: center;
            background-color: #f9e6e7;
            padding: 5px 0;
            color: #c7000b;
            position: relative;
            .ope-hide-btn {
                color: #c7000b;
                position: absolute;
                top: 0px;
                right: 10px;
                font-size: 20px;
                font-weight: bold;
                cursor: pointer;
            }
        }
        .list {
            padding: 10px;
            // padding-right: 20px;
            height: calc(100% - 30px);
            overflow-y: auto;
            // background-color: #f6f7f8;
            .item {
                // border-top: 1px solid #bfbbbb69; 
                // border-bottom: 1px solid #bfbbbb69;
                position: relative;

                // &:first-child{
                //     border-top: 1px solid #F2F2F2;
                //     border-bottom: 1px solid #F2F2F2;
                // }
                // &:not(:first-child) {
                //     border-bottom: 1px solid #F2F2F2;
                // }
                // .down-entity {

                // }
                &+.item {
                    margin-top: 10px;
                }
            }
        }
    }
}
</style>