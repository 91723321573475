const state = {
    downloads: [],
    downloadList: {}
}

const mutations = {
    ADD_DOWNLOAD(state, file) {
      state.downloads.push({
        id: file.id,
        name: file.name,
        size: file.docSize,
        progress: 0,
        status: 'pending', // pending, downloading, completed, failed
        url: file.viewUrl
      })
      state.downloadList[file.id] = 0
    },
    
    UPDATE_PROGRESS(state, { id, progress }) {
      const download = state.downloads.find(d => d.id === id)
      if (download) {
        download.progress = progress
        state.downloadList[id] = progress
        if (progress >= 100) {
          download.status = 'completed'
        }
      }
    },
    
    SET_ERROR(state, id) {
      const download = state.downloads.find(d => d.id === id)
      if (download) {
        download.status = 'failed'
      }
    }
  }
  
  const actions = {
    addDownload({ commit }, file) {
      commit('ADD_DOWNLOAD', file)
    },
    
    updateProgress({ commit }, payload) {
      commit('UPDATE_PROGRESS', payload)
    },
    
    setError({ commit }, id) {
      commit('SET_ERROR', id)
    }
  }
  
  export default {
    namespaced: true,
    state,
    mutations,
    actions
  }